// display screen size error message if the screen is too small
let windowWidth = window.innerWidth;
let windowHeight = window.innerHeight;
let sizeRatio = 0;

const wrongScreenDiv = document.getElementById("wrong-screen-size");
const placeholder = document.getElementsByClassName("placeholder");
const placeholder2div = document.getElementById("placeholder2div");
const container = document.getElementById("container");
const minHeight = 650;
const minWidth = 850;
const maxHeight = 982;
const maxWidth = 1512;
const idealRatio = maxWidth / maxHeight;
const currentRatio = windowWidth / windowHeight;

// if the dimensions of the screen change, update the variables
window.addEventListener("resize", () => {
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
  sizeRatio = windowWidth / windowHeight;
  if (sizeRatio < idealRatio) {
    placeholder[0].classList.remove("w-auto");
    placeholder[0].classList.remove("h-screen");
    placeholder[1].classList.remove("w-auto");
    placeholder[1].classList.remove("h-screen");
    container.classList.remove("justify-center");
    placeholder[0].classList.add("w-screen");
    placeholder[0].classList.add("h-auto");
    placeholder[1].classList.add("w-screen");
    placeholder[1].classList.add("h-auto");
    container.classList.add("justify-start");
  } else {
    placeholder[0].classList.remove("w-screen");
    placeholder[0].classList.remove("h-auto");
    placeholder[1].classList.remove("w-screen");
    placeholder[1].classList.remove("h-auto");
    container.classList.remove("justify-start");
    placeholder[0].classList.add("w-auto");
    placeholder[0].classList.add("h-screen");
    placeholder[1].classList.add("w-auto");
    placeholder[1].classList.add("h-screen");
    container.classList.add("justify-center");
  }
  if (
    // windowWidth < minWidth ||
    // windowHeight < minHeight ||
    //   if the device is touch screen
    "ontouchstart" in document.documentElement === true ||
    //   if the device has coarse pointer
    window.matchMedia("(pointer: coarse)").matches === true
  ) {
    wrongScreenDiv.classList.add("flex");
    wrongScreenDiv.classList.add("w-screen");
    wrongScreenDiv.classList.add("h-screen");
    wrongScreenDiv.classList.add("top-0");
    wrongScreenDiv.classList.add("left-0");
    wrongScreenDiv.classList.remove("hidden");
    wrongScreenDiv.classList.remove("w-0");
    wrongScreenDiv.classList.remove("h-0");
    container.classList.add("hidden");
  } else {
    wrongScreenDiv.classList.add("hidden");
    wrongScreenDiv.classList.add("w-0");
    wrongScreenDiv.classList.add("h-0");
    wrongScreenDiv.classList.remove("flex");
    wrongScreenDiv.classList.remove("w-screen");
    wrongScreenDiv.classList.remove("h-screen");
    wrongScreenDiv.classList.remove("top-0");
    wrongScreenDiv.classList.remove("left-0");
    container.classList.remove("hidden");
  }
});

if (currentRatio < idealRatio) {
  placeholder[0].classList.remove("w-auto");
  placeholder[0].classList.remove("h-screen");
  placeholder[1].classList.remove("w-auto");
  placeholder[1].classList.remove("h-screen");
  container.classList.remove("justify-center");
  placeholder[0].classList.add("w-screen");
  placeholder[0].classList.add("h-auto");
  placeholder[1].classList.add("w-screen");
  placeholder[1].classList.add("h-auto");
  container.classList.add("justify-start");
} else {
  placeholder[0].classList.remove("w-screen");
  placeholder[0].classList.remove("h-auto");
  placeholder[1].classList.remove("w-screen");
  placeholder[1].classList.remove("h-auto");
  container.classList.remove("justify-start");
  placeholder[0].classList.add("w-auto");
  placeholder[0].classList.add("h-screen");
  placeholder[1].classList.add("w-auto");
  placeholder[1].classList.add("h-screen");
  container.classList.add("justify-center");
}

if (
  //   windowWidth < minWidth ||
  //   windowHeight < minHeight ||
  //   if the device is touch screen
  "ontouchstart" in document.documentElement === true ||
  //   if the device has coarse pointer
  window.matchMedia("(pointer: coarse)").matches === true
) {
  wrongScreenDiv.classList.add("flex");
  wrongScreenDiv.classList.add("w-screen");
  wrongScreenDiv.classList.add("h-screen");
  wrongScreenDiv.classList.add("top-0");
  wrongScreenDiv.classList.add("left-0");
  wrongScreenDiv.classList.remove("hidden");
  wrongScreenDiv.classList.remove("w-0");
  wrongScreenDiv.classList.remove("h-0");
  container.classList.add("hidden");
} else {
  wrongScreenDiv.classList.add("hidden");
  wrongScreenDiv.classList.add("w-0");
  wrongScreenDiv.classList.add("h-0");
  wrongScreenDiv.classList.remove("flex");
  wrongScreenDiv.classList.remove("w-screen");
  wrongScreenDiv.classList.remove("h-screen");
  wrongScreenDiv.classList.remove("top-0");
  wrongScreenDiv.classList.remove("left-0");
  container.classList.remove("hidden");
}

const button = document.getElementById("button-div");
const input = document.getElementById("textbox");
const scoreDiv = document.getElementById("score-input");
const scoreText = document.getElementById("score-text");
const secondFace = document.getElementById("second-face");
const button2 = document.getElementById("button2-div");

const keywordlist1 = [
  "let's",
  "lets",
  "our",
  "ours",
  "ourselves",
  "us",
  "we",
  "we'd",
  "we'll",
  "we're",
  "we've",
  "weve",
];
const keywordlist2 = [
  "absolute",
  "absolutely",
  "accurate",
  "accuracy",
  "all",
  "altogether",
  "always",
  "apparent",
  "assure",
  "assurance",
  "blatant",
  "blatantly",
  "certain",
  "certainty",
  "clear",
  "clearly",
  "commit",
  "commitment",
  "commits",
  "committed",
  "committing",
  "complete",
  "completely",
  "completes",
  "confidence",
  "confident",
  "confidently",
  "correct",
  "correctly",
  "corrected",
  "defined",
  "definite",
  "definitely",
  "definitive",
  "directly",
  "distinct",
  "distinct",
  "entire",
  "especially",
  "essential",
  "ever",
  "every",
  "everybody",
  "everyday",
  "everyone",
  "everything",
  "everytime",
  "everywhere",
  "evident",
  "exact",
  "exactly",
  "explicit",
  "explicitly",
  "fact",
  "facts",
  "factual",
  "forever",
  "frankly",
  "fundamental",
  "fundamentals",
  "fundamentally",
  "guarantee",
  "guaranteed",
  "guarantees",
  "implicit",
  "indeed",
  "inevitably",
  "inevitable",
  "infallible",
  "infallibly",
  "invariable",
  "invariably",
  "irrefutable",
  "irrefutably",
  "must",
  "mustnt",
  "mustve",
  "namely",
  "necessarily",
  "necessary",
  "never",
  "nothing",
  "nowhere",
  "obvious",
  "obviously",
  "particularly",
  "perfect",
  "perfected",
  "perfecting",
  "perfection",
  "perfectly",
  "perfects",
  "positive",
  "positively",
  "positives",
  "promise",
  "promised",
  "promises",
  "proof",
  "prove",
  "proved",
  "proofs",
  "proving",
  "pure",
  "purely",
  "pureness",
  "purest",
  "purity",
  "specific",
  "specifically",
  "specifics",
  "sure",
  "surely",
  "total",
  "totally",
  "true",
  "truest",
  "truly",
  "truth",
  "unambiguous",
  "unambiguously",
  "undeniable",
  "undeniably",
  "undoubtly",
  "undoubted",
  "unquestionable",
];

function countWords(text, keywords1, keywords2) {
  let contain1 = false;
  let contain2 = false;
  let contain1or2 = false;

  text
    .toLowerCase()
    .split(" ")
    .forEach((word) => {
      // remove quotations that are not part of a word
      word = word.replace(/[^a-zA-Z0-9']/g, "");
      console.log(word);
      if (keywords1.includes(word)) contain1 = true;
      if (keywords2.includes(word)) contain2 = true;
    });

  if (contain1 && contain2) contain1or2 = true;

  return contain1or2;
}

button.addEventListener("click", () => {
  let count = 0;
  let score = 0;
  const inputText = input.value;
  //   split the inputText into sentences, by full stop, exclamations and question marks, but don't create an empty string at the end, don't include the punctuation in the sentence
  if (inputText !== "") {
    const sentences = inputText.split(/(?<=[.!?])\s*/).filter((sentence) => {
      return (
        sentence.length > 0 &&
        sentence !== "!" &&
        sentence !== "?" &&
        sentence !== "." &&
        sentence !== "..." &&
        sentence !== "…" &&
        sentence !== '"' &&
        sentence !== "'" &&
        sentence !== "’" &&
        sentence !== "‘" &&
        sentence !== "”" &&
        sentence !== "“"
      );
    });
    // remove all punctuation except quotes from each sentence
    console.log(sentences);
    sentences.forEach((sentence, index) => {
      sentences[index] = sentence.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
    });

    console.log(sentences);
    //   remove the empty strings from the sentences
    sentences.forEach((sentence, index) => {
      if (sentence === "") sentences.splice(index, 1);
    });
    //   remove space at beginning and end of each sentence
    sentences.forEach((sentence, index) => {
      sentences[index] = sentence.trim();
    });
    // run the function countWords on each sentence and add the returned value to the score
    sentences.forEach((sentence) => {
      if (countWords(sentence, keywordlist1, keywordlist2)) count++;
    });
    //   calculate the score, if there are no sentences, set the score to 0
    if (sentences.length === 0) score = 0;
    else score = count / sentences.length;
    //   round the score to 3 significant figures
    score = Math.round(score * 1000) / 1000;
    //   set the content of the score div to the score
    scoreDiv.textContent = "has a consensus score of " + score;

    placeholder2div.classList.remove("hidden");
    input.classList.add("hidden");
    button.classList.add("hidden");
    button2.classList.remove("hidden");
    scoreText.classList.remove("hidden");
  }
});
